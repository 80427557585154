.dev-log {
    padding: 1rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: var(--altbackground);
    color: var(--foreground);
}

.dev-log p {
    margin: 0;
    padding: 0.5rem;
}

.middle-row {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
}

.bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.project {
    font-size: 20px;
    font-weight: bold;
}

.repository {
    font-size: 20px;
}

.branch {
    font-size: 20px;
}

.message {
    font-size: 14px;
    text-align: left;
    width: 100%;
}

.user {
    font-size: 10px;
    text-align: left;
}

.date {
    font-size: 10px;
    text-align: right;
}