.dev-log-container {
    padding: 0.8rem;
    transition: color 0.2s, padding 0.2s;
    max-width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--altbackground);
    color: var(--foreground);
}

.loading-container {
    width: 100%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--altbackground);
    border-radius: 4px;
    color: var(--foreground);
    animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 0.6;
    }
}

.branches-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.dev-log-container:hover {
    color: var(--hover);
    padding: 1rem;
}

.top-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.project {
    font-size: 20px;
    font-weight: bold;
}

.repository {
    font-size: 20px;
}