.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.App-title2 {
    font-size: clamp(2rem, 8vw, 4rem);
    margin: 0;
    color: var(--foreground);
    text-align: center;
}

.App-subtitle {
    font-size: clamp(1rem, 4vw, 1.5rem);
    margin: 0.5rem 0 2rem 0;
    color: var(--foreground);
    text-align: center;
}

@media screen and (max-width: 768px) {
    .home-container {
        padding: 1rem;
    }
}