html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.App-body {
  display: flex;
  flex: 1;
  overflow: hidden;
  height: 100%;
}

.App-title {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.App-header {
  color: var(--foreground);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.App-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  margin-top: 1rem;
  font-size: 1.2rem;
  color: var(--altbackground);
  background-color: var(--foreground);
  border: none;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  transition: padding 0.2s ease;
}

.App-button:hover {
  background-color: var(--hover);
  padding: 1rem 1.75rem;
}

.App-title {
  font-size: 1.1rem;
  align-content: left;
  margin: 0;
}

.App-sidebar {
  width: 120px;
  height: 100%;
  background-color: var(--altbackground);
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.App-nav {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: top;
}

.App-main {
  flex: 1;
  padding: 1rem;
  background-color: var(--background);
  overflow-y: auto;
}

.App-iconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  margin-top: auto;
  margin-bottom: 1.5rem;
}

* {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--foreground) transparent;
}

/* Webkit browsers */
::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background-color: rgba(26, 26, 26, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--foreground);
  border-radius: 4px;
  opacity: 0.8;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--hover);
}