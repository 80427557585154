.nav-button {
    display: inline-flex;
    align-items: center;
    justify-content: left;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    background: transparent;
    align-content: left;
    border-color: transparent;
    transition: color 0.2s;
    color: var(--foreground);
}

.nav-button:hover {
    color: var(--hover);
    font-size: 20px;
    border-color: var(--hover);
}

.nav-button.active {
    color: var(--foreground);
    font-size: 20px;
    border-color: var(--foreground);
}