body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Fira Code', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@font-face {
  font-family: 'Fira Code';
  src: url('./Resources/Fira_Code/static/FiraCode-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}