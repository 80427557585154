.dev-log-branch {
    padding: 0.8rem;
    transition: color 0.2s, padding 0.2s;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--background);
    color: var(--foreground);
}

.branch-header {
    display: flex;
    align-items: center;
}

.devlog-container {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.dev-log-branch:hover {
    color: var(--hover);
    padding: 1rem;
}

.load-more-button {
    width: 50%;
    min-width: 150px;
    max-width: 300px;
    margin: 1rem auto;
    font-family: inherit;
    padding: 0.8rem;
    display: block;
    background-color: var(--altbackground);
    color: var(--foreground);
    border: transparent;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.load-more-button:hover {
    background-color: var(--foreground);
    color: var(--background);
    background-color: var(--foreground);
    padding: 1rem;
}

.load-more-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}