:root {
    /* Light theme (default) */
    --foreground: lime;
    --darkbackground: #323734;
    --altbackground: #1a1a1a;
    --background: #252926;
    --hover: rgb(196, 239, 196);
}

[data-theme='dark'] {
    --foreground: #ffffff;
    --background: #1a1a1a;
    --hover: #333333;
}